@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "futuraltbt";
  src:
    url("./fonts/FuturaBT-Light.woff") format("woff"),
    url("./fonts/FUTURAL.TTF") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: futuraltbt !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.centerImage {
  width: -webkit-fill-available;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.center {
  background: white;
  text-align: center;
}

.center a:hover {
  text-decoration: none;
}

.center a {
  text-decoration: none;
  color: black;
  padding: 5px;
  font-size: 15px;
}

@media screen and (max-width: 400px) {
  .center a {
    font-size: 12px;
  }
}

.inactive {
  display: none;
}

#logoImage {
  max-width: 30% !important;
  justify-self: center;
  max-height: 90px;
}

.footerMargin {
  justify-content: center;
  display: flex;
  margin-bottom: 10px;
}

.footer {
  display: grid;
  place-items: center;
  row-gap: 0.2rem;
}

.footer > nav {
  justify-items: center;
}

.hoverable-text {
  opacity: 0;
}

.hoverable-text:hover {
  opacity: 1;
}

.hoverable-text-image:hover .hoverable-text {
  opacity: 1;
  transition: 0.8s all;
}

.hoverable-text-image:hover {
  opacity: 0.7;
  transition: 0.8s all;
}
